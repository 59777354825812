import React from "react";
import styles from "../Modules/InfoCard.module.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowLeft,
} from "@fortawesome/free-solid-svg-icons";
class InfoCard extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      projectName: "",
      projectTechnologies: [],
      projectDescription: "",
      projectFunctionalities: "",
      projectModeling: "",
      funSelectedPic: "",
      modSelectedPic: "",
      demoLink: "",
      codeLink: "",
      projectLogo: "",
      imgAlt: "",
      functionalitiesPics: "",
      modelingPics: "",
      caseStudyBackground: "",
      backgrounds_attributions:"",
    };
  }

  static getDerivedStateFromProps(props, state) {
    return {
      demo:props.demo,
      demoVideo:props.demoVideo,
      projectName: props.name,
      codeLink: props.gitRepo,
      projectLogo: props.logo,
      projectTechnologies: props.projectTechnologies,
      projectDescription: props.projectDescription,
      projectFunctionalities: props.projectFunctionalities,
      projectModeling: props.projectModeling,
      imgAlt: props.imgAlt,
      functionalitiesPics: props.functionalitiesPics,
      modelingPics: props.modelingPics,
      backgroundPicture: props.backgroundPicture,
      backgrounds_attributions: props.backgrounds_attributions,
    };
  }
  
  render() {
    return (
      <div className={styles.page}>
        <ul className={styles.navigation}>
          <li>
            <a
                title="Go back to the portfolio"
                href={"/#"+this.state.projectName}
                style={{ float: "left" }}
            >
              <FontAwesomeIcon icon={faArrowLeft} className={styles.icon} />
              Back
            </a>
          </li>
          {
            this.state.demo ?
                <li>
                  <a
                      href={this.state.demo}
                      target="_blank"
                      rel="noopener noreferrer"
                      title="Go to this projects hosted version so that you can test the project"
                  >
                    Demo
                  </a>
                </li>
            : <></>
          }
          {
            this.state.demoVideo ?
                <li>
                  <a
                      href={this.state.demoVideo}
                      target="_blank"
                      rel="noopener noreferrer"
                      title="Go to this projects hosted version so that you can test the project"
                  >
                    Watch Demo Video
                  </a>
                </li>
            : <></>
          }
          <li>
            <a
                href={this.state.codeLink}
                target="_blank"
                rel="noopener noreferrer"
                title="Go to this projects github ripository to view code"
            >
              Source Code
            </a>
          </li>
        </ul>
        <div className={styles.caseStudy}>
          <div className={styles.container}>
            <img className={styles.backgroundPicture} src={this.state.backgroundPicture} alt="background"/>
            <img src={this.state.projectLogo} alt="project logo"/>
            <div className={styles.subcontainer}>
              <div className={styles.sectionsNavigation}>
                <a href={"#Description"}>📌 Description</a>
                <a href={"#Functionalities"}>📌 Functionalities</a>
                <a href={"#Modelisation & Diagrams"}>📌 Modelisation & Diagrams</a>
              </div>
              <div className={styles.copy}>
                <h1 id={"Description"}>{this.state.projectName}</h1>
                <p>{this.state.projectDescription}</p>
                <h2 id={"Functionalities"}>Functionalities</h2>
                <img src={this.state.functionalitiesPics} alt="project interface screenshot"/>
                <p>{this.state.projectFunctionalities}</p>
                {
                  this.state.projectModeling?
                      <>
                        <h2 id={"Modelisation & Diagrams"}>Modelisation & Diagrams</h2>
                        <img src={this.state.modelingPics} alt="project database diagram"/>
                        <p>{this.state.projectModeling}</p>
                      </>:<></>
                }
                {
                  this.state.demoVideo ?
                      <>
                        <h2>Watch demonstration video</h2>
                        <iframe
                            title="project demonstration video"
                            src={this.state.demoVideo}
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                            allowFullScreen
                        ></iframe>
                      </>
                  :
                     <></>
                }
              </div>
            </div>
            <div className={styles.footer}>
              {this.state.backgrounds_attributions}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default InfoCard;